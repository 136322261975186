import "./styles.scss"

import React from "react"

import Button from "components/Button"

const About = () => {
  return (
    <section className="home-about">
      <div className="container-fluid">
        <span className="home-about__sub">O Trust</span>
        <h2>Trust Investment Projekt 23 sp. z o.o. </h2>
        <div className="row">
          <div className="col-lg-5 col-md-6">
            <img
              src={require("assets/images/home/home-about-image.png").default}
              alt=""
            />
          </div>
          <div className="col-md-6 offset-lg-1">
            <h4>
              Trust Investment Projekt 23 to spółka z ograniczoną
              odpowiedzialnością należąca do Grupy Trust –uznanego, kieleckiego
              dewelopera, który realizuje projekty deweloperskie w sektorze
              nieruchomości mieszkaniowych i komercyjnych na terenie całej
              Polski.
            </h4>
            <p>
              Wychodząc naprzeciw rosnącemu zainteresowaniu naszych Klientów we
              współtworzeniu naszych inwestycji, a także poszukując dodatkowych
              form finansowania realizacji ambitnego projektu deweloperskiego,
              inwestycji KRAKOWSKA ROGATKA, przygotowaliśmy rozwiązanie
              pozwalające zainwestować naszym Klientom środki poprzez udzielenie
              pożyczki inwestycyjnej spółce deweloperskiej realizującej to
              przedsięwzięcie - Trust Investment Projekt 23.
            </p>
            <Button to="/o-trust/">zobacz</Button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About
